import { LIST_MODULE_ITEM_WIDTH } from './module';
import { UPC_ANY } from './userAgent';
import { scaleX } from './window';

export const MANUFACTURER = (() => {
  // The following checks for the hash at startup only (see tizen/*/index.html)
  if (window.location.hash === '#tizen' || typeof tizen !== 'undefined') return 'tizen';
  // eslint-disable-next-line camelcase
  if (typeof SmartTvA_API !== 'undefined') return 'philips';
  if (window.location.hash === '#webos' || typeof webOS !== 'undefined') return 'lg';
  if (UPC_ANY) return 'upc';
  return 'generic';
})();

export const PLATFORM_NAME = `tv-reactjs.${MANUFACTURER}`;

export const TAG_COMMANDER_SITE_NAME = `playsuisse.${PLATFORM_NAME}`;

export const SSO_CLIENT_ID = '51c251bf-9b3c-4972-abcb-3407a36d8598';
export const SSO_SCOPE = 'email profile openid offline_access';

export const LETTERBOX_NAME = 'RIOLetterbox';

export const UNKNOWN_PAGE_ID_EVENT = 'unknown';

export const DEFAULT_SHADOW = `text-shadow: 0px 0px ${scaleX(25)} rgba(0,0,0,0.9)`;
export const TW_DEFAULT_SHADOW = 'drop-shadow-[0_0_60px_rgba(0,0,0,0.9)]';

export const DEFAULT_WATCH_PROGRESS_BAR_WIDTH = 550;

export const ROUNDED_BORDER_RADIUS = 'rounded-[99999]';

export const NEXT_EPISODE_MODULE_ID = 'next_episode';

export const THIRTY_DAYS = 30 * 24 * 60 * 60 * 1000;

export const HERO_FOCUSED_ITEM_CHANGE_WAIT_DURATION = 600;

export const HERO_COLLECTION_LOGO_SIZE = {
  width: LIST_MODULE_ITEM_WIDTH,
  height: 186,
};

export const SKELETON_START_COLOR = 'rgba(255,255,255,0.1)';
export const SKELETON_END_COLOR = 'rgba(255,255,255,0.3)';

export const FADING_VOLUME_INTERVAL = 100;

export const CDN_URL = 'https://cdn.playsuisse.ch';
export const AKAMAI_CDN_URL = 'https://playsuisse-static.akamaized.net';

export const HERO_CATEGORY_LOGO_SIZE = {
  width: 1200,
  height: 160,
};
