import React, { forwardRef, memo, PropsWithChildren, useEffect } from 'react';

import { useFocusable } from '@noriginmedia/norigin-spatial-navigation';
import { useDidMount } from '@srgssr/rio-common/react';

import { FirstParameter, PropsWithClassName } from '../../types';
import { FocusBlurHandler, PressHandler } from '../../types/focus';
import { setComponentRefs } from '../../utils';
import { setImageDimensions } from '../../utils/image';
import { cn } from '../../utils/style';
import { RATIO, scaleX, scaleY } from '../../utils/window';

export const TEASER_WIDTH = 375;
export const TEASER_HEIGHT = Math.ceil(TEASER_WIDTH * RATIO);
export const TEASER_FOCUS_INDICATOR_SIZE = 7;

export type TeaserRef = HTMLElement | null;

type OnFocusBlur = FocusBlurHandler<{ index: number }>;
type OnMount = (params: Pick<FirstParameter<OnFocusBlur>, 'index' | 'focusKey'>) => void;
type OnUpdate = (params: Pick<FirstParameter<OnFocusBlur>, 'index'>) => void;
type OnPress = PressHandler<Pick<FirstParameter<OnFocusBlur>, 'index'>>;

export type TeaserProps = PropsWithClassName<
  PropsWithChildren<{
    index: number;
    imageUrl: string;
    ranking?: boolean;
    $width?: number;
    $height?: number;
    opacity?: number;
    hasTVPreferredFocus?: boolean;
    onMount?: OnMount;
    onUpdate?: OnUpdate;
    onPress?: OnPress;
    onFocus?: OnFocusBlur;
    onBlur?: OnFocusBlur;
  }>
>;

export const Teaser = memo(
  forwardRef<TeaserRef, TeaserProps>(
    (
      {
        index,
        imageUrl,
        ranking = false,
        opacity,
        children,
        className,
        hasTVPreferredFocus,
        onPress,
        onFocus,
        onBlur,
        onMount,
        onUpdate,
        $width = TEASER_WIDTH,
        $height = TEASER_HEIGHT,
      },
      propRef
    ) => {
      const { ref, focusKey, focused, focusSelf } = useFocusable({
        onEnterRelease: () => onPress?.({ index }),
        onFocus: (layout, _, details) => {
          onFocus?.({ layout, focusKey, index, details });
        },
        onBlur: (layout, _, details) => {
          onBlur?.({ layout, focusKey, index, details });
        },
      });

      useDidMount(() => {
        onMount?.({ focusKey, index });
      });

      useEffect(() => {
        if (hasTVPreferredFocus) focusSelf();
      }, [focusSelf, hasTVPreferredFocus]);

      const url = setImageDimensions(imageUrl, scaleX(TEASER_WIDTH, null), TEASER_HEIGHT);

      return (
        <div
          className={cn('relative box-border transition-opacity', className)}
          style={{
            opacity,
          }}
          ref={setComponentRefs(ref, propRef)}
        >
          <div className='relative flex p-[7]'>
            <img
              className={`overflow-hidden bg-[rgba(255,255,255,0.05)] object-cover transition-[border-radius] ${
                focused ? 'rounded-none' : 'rounded-[8]'
              }`}
              style={{
                boxShadow: ranking ? 'rgba(227, 31, 43, 0.8) 1px 1px 18px' : undefined,
                width: scaleX($width),
                height: scaleY($height),
              }}
              alt=''
              src={url}
            />
            <div
              className={`absolute flex overflow-hidden transition-[border-radius] inset-[0] m-[7] ${
                focused ? 'rounded-none' : 'rounded-[8]'
              }`}
            >
              {children}
            </div>
          </div>
          <div
            className={`absolute top-0  size-full border-solid 
             transition-[border-color] rounded-[8] ${focused ? 'border-srgWhite delay-200' : 'border-[rgba(255,255,255,0)] delay-0'} `}
            style={{ borderWidth: scaleX(7, null) }}
          />
        </div>
      );
    }
  )
);
