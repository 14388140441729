import { EXTRA_AUDIO_LANGUAGES, ALL_LANGUAGES } from './languages.js';

export const DEFAULT_LOCALE = 'de';

export const USER_LOCALES: Array<typeof DEFAULT_LOCALE | 'fr' | 'it' | 'rm'> = [DEFAULT_LOCALE, 'fr', 'it', 'rm'];
export type Localized<T> = {
  [key in typeof USER_LOCALES[0]]: T;
};

export const USER_LANGUAGES = ALL_LANGUAGES.filter((l) => USER_LOCALES.includes(l['639-1'] as typeof USER_LOCALES[0]));

export const SUBTITLE_LANGUAGES = ALL_LANGUAGES.map((l) => l['639-1']);
export const AD_LANGUAGES = { fr: 'teo', de: 'hkk', it: 'hnn', rm: 'hak' };
export const AUDIO_LANGUAGES = [...ALL_LANGUAGES.map((l) => l['639-1']), ...EXTRA_AUDIO_LANGUAGES.map((l) => l.code)];

export enum ReservedPageAndModuleId {
  HOMEPAGE = 'homepage',
  DISCOVERY = 'discovery',
  MY_LIST = 'my_list',
  CONTINUE_WATCHING = 'continue_watching',
  AUDIO_DESCRIPTION = 'audio_description',
  ONBOARDING = 'onboarding',
  ERROR = '404',
  CATEGORY = 'category',
  MORE_LIKE_THIS_PREFIX = 'more_like_this_',
  SEARCH_PREFIX = 'search_',
  CONTENT_BASED_RECOS_PREFIX = 'content_based_recos_',
}

export const HOSTNAME_REGEX = /^(?:https?:)?(?:\/\/)?([^/?]+)/;

export const ASSET_TYPE = {
  movie: 'Movie',
  series: 'Series',
  episode: 'Episode',
} as const;

export const LOGIN_OLD_V1_POLICY = 'b2c_1a__signinv1_playch';

export const AZURE_CONSENT_TOKEN_KEYS = {
  PLAYCH_DATAPROCESSING: 'consent_playch_dataprocessing',
  PLAYCH_PERSONALIZATION: 'consent_playch_personalization',
  GLOBAL_DP: 'consent_global_dp',
  GLOBAL_TOS: 'consent_global_tos',
} as const;
export const AZURE_CONSENT_TOKEN_KEYS_ARRAY = Object.values(AZURE_CONSENT_TOKEN_KEYS);

export const CIDAAS_STATIC_PATHNAME = {
  WELL_KNOWN: '/.well-known/openid-configuration',
  DEVICE_VERIFICATION: '/token-srv/device/verify',
  BLUE_TV_USER_EXISTENCE: '/plug-blue-tv/userexistence',
  BLUE_TV_AUTHENTICATION: '/plug-blue-tv/authentication',
  BLUE_TV_VERIFICATION_INITIATION: '/verification-srv/authentication/bluetv/initiation',
  BLUE_TV_CONTINUE_LOGIN: '/login-srv/verification/sdk/login',
} as const;

export const CIDAAS_SETTINGS_STATIC_PATHNAME = {
  PROFILE: '/profile/settings',
  PORTABILITY: '/profile/access-eu',
  PLAYSUISSE_PROFILE: '/profile/playsuisse',
} as const;
