import { clsx, type ClassValue } from 'clsx';
import { twMerge } from 'tailwind-merge';

import { isTizen5, isTizen6 } from './userAgent';

type CSSProperty = 'filter' | 'backdrop-filter';

export const cn = (...inputs: ClassValue[]) => twMerge(clsx(inputs));

export const isCSSPropertySupported = (property: CSSProperty) => {
  switch (property) {
    case 'filter':
      return !isTizen5();
    case 'backdrop-filter':
      return !isTizen6();
    default:
      return true;
  }
};

export const FILL = ' size-full ';

export const ABSOLUTE_FILL = ` flex absolute inset-[0]  ${FILL} `;
